/** Slide Transition Effect **/

.slideTranslate-enter {
  opacity: 0;
  transform: translate(300%, 0);
  position: fixed;
}

.slideTranslate-enter.slideTranslate-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 250ms ease-in 250ms, transform 250ms ease-in-out 250ms;
}

.slideTranslate-exit {
  opacity: 1;
  position: fixed;
  transform: translate(0, 0);
}

.slideTranslate-exit.slideTranslate-exit-active {
  opacity: 0;
  transform: translate(-300%, 0);
  transition: opacity 175ms ease-in, transform 175ms ease-in-out;
}

/** Slide Transition Effect **/

/** Fade Transition Effect **/

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 600ms ease-in;
}

/** Fade Transition Effect **/

.main-container {
  width: 100%;
}
